import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DarkModeProvider } from '@components';
import { Panel } from '@custom';
import { AnimatePresence } from 'framer-motion';

import {
  Auth,
  SaisonPage,
  LogePage,
  LogeEdit,
  PlayerPage,
  PlayerEdit,
  BetPage,
  BetEdit,
  ClubPage,
  ClubEdit,
  MatchPage,
  MatchEdit,
  SaisonEdit,
  Home
} from '@pages';

import {
  TrophyIcon,
  GlobeAsiaAustraliaIcon,
  UserGroupIcon,
  UserIcon,
  WalletIcon,
  HomeIcon,
  TicketIcon
} from '@heroicons/react/20/solid';

const linksTitle = {
  home: 'Accueil',
  season: 'saisons',
  club: 'clubs',
  match: 'matchs',
  loge: 'loges',
  player: 'joueurs',
  bet: 'paris'
};
const linksPage = {
  home: <Home />,
  season: <SaisonPage />,
  club: <ClubPage />,
  match: <MatchPage />,
  loge: <LogePage />,
  player: <PlayerPage />,
  bet: <BetPage />
};

const linksEdit = {
  home: <Home />,
  season: <SaisonEdit />,
  club: <ClubEdit />,
  match: <MatchEdit />,
  loge: <LogeEdit />,
  player: <PlayerEdit />,
  bet: <BetEdit />
};
const linksIcon = {
  home: <HomeIcon className="h-5 w-5" aria-hidden="true" />,
  season: <GlobeAsiaAustraliaIcon className="h-5 w-5" aria-hidden="true" />,
  club: <UserGroupIcon className="h-5 w-5" aria-hidden="true" />,
  match: <TrophyIcon className="h-5 w-5" aria-hidden="true" />,
  loge: <TicketIcon className="h-5 w-5" aria-hidden="true" />,
  player: <UserIcon className="h-5 w-5" aria-hidden="true" />,
  bet: <WalletIcon className="h-5 w-5" aria-hidden="true" />
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
});
const router = createBrowserRouter([
  {
    path: '/',
    loader: async () => redirect('/home')
  },
  {
    path: '/:pageName',
    element: (
      <Auth>
        <Panel links={linksPage} icons={linksIcon} titles={linksTitle} />
      </Auth>
    )
  },
  {
    path: '/edit/:pageName/:id?',
    element: (
      <Auth>
        <Panel links={linksEdit} icons={linksIcon} titles={linksTitle} />
      </Auth>
    )
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <DarkModeProvider>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <AnimatePresence wait>
          <RouterProvider router={router} />
        </AnimatePresence>
      </QueryClientProvider>
    </React.StrictMode>
  </DarkModeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
