import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const SortOptions = (props) => {
  const { setPagination = () => {}, pagination = null, totalElement = 0 } = props;

  const paginationOptions = [
    { label: '10', value: 25 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 50 },
    { label: 'All', value: totalElement }
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <Menu as="div" className="relative flex justify-center items-center">
      {({ open }) => (
        <>
          <div className="flex">
            <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-200 dark:hover:text-white">
              Show {` ${pagination} of ${totalElement}`}
              <ChevronDownIcon
                className={`${open ? '-rotate-180' : 'rotate-0'} transition -mr-1 ml-1 h-5 w-5`}
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute -bottom-14 z-40 mt-2 w-20 rounded-md p-1 flex flex-col items-end bg-white dark:bg-gray-800 dark:border dark:border-gray-600 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none dark:shadow-gray-700 dark:shadow-md">
              {paginationOptions.map((option, index) => (
                <Menu.Item key={`optin_${index}`}>
                  <a
                    onClick={() => setPagination(option?.value)}
                    className={classNames(
                      option?.value === pagination
                        ? `bg-gray-200 dark:bg-teal-400/10  dark:ring-1 dark:ring-inset dark:text-teal-300 dark:ring-teal-300`
                        : `hover:bg-gray-100 dark:bg-teal-700/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300`,
                      index === 0 && 'rounded-t-md',
                      index === paginationOptions.length - 1 && 'rounded-b-md',
                      ' cursor-pointer w-full flex justify-center items-center overflow-hidden text-sm font-medium transition py-1 px-2'
                    )}>
                    {option?.label}
                  </a>
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default SortOptions;
