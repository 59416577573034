import { useState, useRef, useEffect } from 'react';
import { TrashIcon, DocumentIcon } from '@heroicons/react/24/solid';

const renderAccept = (str) =>
  str === '*'
    ? ''
    : str
        .split(',')
        .map((o) => o.replace('.', '').toUpperCase())
        .join(', ');

const cancelEffect = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const SingleInputFile = (props) => {
  const {
    label = '',
    accept = '*',
    setFile = () => {},
    defaultValue = undefined,
    name = '',
    ...rest
  } = props;

  const [preview, setPreview] = useState(undefined);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const inputRef = useRef();

  const removeFile = (e) => {
    cancelEffect(e);
    if (preview?.id) setToBeDeleted(preview?.id);

    setPreview(null);
  };

  const updatePreview = (file) => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => setPreview({ url: reader.result, name: file.name }),
      false
    );
    if (['image/svg+xml', 'image/png', 'image/jpeg', 'image/gif'].includes(file.type))
      reader.readAsDataURL(file);
    else setPreview({ name: file.name });
  };

  const uploadFile = (e, type = 'click') => {
    cancelEffect(e);

    let files = null;

    if (type === 'click') files = e.target.files;
    else {
      files = e.dataTransfer.files;
      inputRef.current.files = files;
    }

    for (let i = 0; i < files.length; i++) {
      Object.defineProperty(files.item(i), 'name', {
        writable: true,
        value: files
          .item(i)
          .name.normalize('NFD')
          .replace(/[\u0300-\uFFFF]/g, '')
          .replace(/ /g, '-')
      });
    }

    setFile(files.item(0));
    setPreview([]);

    updatePreview(files.item(0));
  };

  useEffect(() => {
    setPreview(defaultValue);
  }, [defaultValue]);

  return (
    <div className="relative w-full group">
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
        {label}
      </label>
      {toBeDeleted && (
        <input
          type="hidden"
          name={['deleted.', name?.split('.')?.at(-1)].join('')}
          value={toBeDeleted}
        />
      )}
      <div className="flex items-center justify-center w-full">
        <label
          htmlFor={`dropzone-file_${name}`}
          className="transition flex items-center justify-center w-full h-32 py-2 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 peer-focus:border-teal dark:hover:bg-bray-800 dark:bg-gray-800 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-700"
          onDrag={(e) => cancelEffect(e)}
          onDragEnter={(e) => cancelEffect(e)}
          onDragLeave={(e) => cancelEffect(e)}
          onDragEnd={(e) => cancelEffect(e)}
          onDragOver={(e) => cancelEffect(e)}
          onDragStart={(e) => cancelEffect(e)}
          onDrop={(e) => uploadFile(e, 'DND')}>
          <div className="flex items-center justify-center gap-6 w-full">
            {preview ? (
              <div className="w-full flex">
                <div className="w-1/2 h-32 py-2 overflow-hidden rounded-l-md border-x dark:border-gray-600">
                  {['png', 'jpeg', 'jpg', 'gif', 'svg'].some((l) => preview?.url?.includes(l)) ? (
                    <img className="h-full w-full object-contain" src={preview?.url} />
                  ) : (
                    <div className="h-full rounded-md flex items-center justify-center">
                      <DocumentIcon className="h-10 w-10 dark:text-gray-200 transition" />
                    </div>
                  )}
                </div>
                <div className="w-1/2 flex flex-col justify-evenly items-center pr-2 text-center">
                  <p className="dark:text-gray-200">{preview?.name}</p>
                  <div className="space-x-2">
                    <button
                      onClick={(e) => removeFile(e)}
                      className="p-1 transition bg-gray-200 hover:bg-gray-300 hover:text-gray-600 dark:bg-gray-600 dark:hover:bg-gray-500 dark:hover:text-gray-700  text-gray-500 rounded-md">
                      <TrashIcon className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 stroke-gray-500">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
                <div>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400 text-center">
                    <span className="font-semibold">Click to upload</span> or drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400 text-center">
                    {renderAccept(accept)}
                  </p>
                </div>
              </>
            )}
          </div>
          <input
            id={`dropzone-file_${name}`}
            type="file"
            className="hidden"
            ref={inputRef}
            accept={accept}
            onChange={(e) => uploadFile(e)}
            name={name}
            {...rest}
          />
        </label>
      </div>
    </div>
  );
};

export default SingleInputFile;
