import { useQuery } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';
import logo from '@assets/logo.svg';
import queries from '@queries';

import { Header } from '@layouts';
import { HotKeys } from '@components';

import { hotKeys } from '@root/config';

const capitalize = ([first = '', ...rest]) => [first.toUpperCase(), ...rest].join('');
const initialsFromEmail = (email) =>
  email
    ?.split('@')?.[0]
    ?.split('.')
    ?.map((word) => word?.toUpperCase()?.[0])
    ?.join('');

function Panel(props) {
  const { pageName } = useParams();
  const navigate = useNavigate();

  const me = useQuery(['me'], () => queries.getAll('/api/users/me'), { retry: false });
  const links = props.links;
  const icons = props.icons;
  const titles = props.titles;

  return (
    <div className={`min-h-screen h-full bg-gray-100 dark:bg-gray-900 pb-10 transition`}>
      <HotKeys data={hotKeys({ navigate, links, titles, capitalize })} />
      <Header
        tabs={Object.keys(links)?.map((e) => ({
          name: capitalize(titles?.[e]),
          active: e === pageName,
          href: `/${e}`,
          icon: icons?.[e]
        }))}
        logoLink="/home"
        userActions={[
          {
            title: 'Déconnexion',
            fnc: () => {
              window.location.replace('/');
              sessionStorage.removeItem('token');
            }
          }
        ]}
        logo={logo}
        classNameLogo="invert dark:invert-0"
        avatar={initialsFromEmail(me?.data?.email)}
        username={me?.data?.username}>
        {links[pageName]}
      </Header>
    </div>
  );
}

export default Panel;
