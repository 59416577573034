import { Section } from '@custom';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import queries from '@queries';
import { Table } from '@components';

function PlayerPage() {
  const navigate = useNavigate();

  const { data: tableData, isLoading: isLoading } = useQuery(['players'], () =>
    queries.getAll(`/api/players`, { populate: ['club.logo'] }).then((res) => res.data)
  );

  return (
    <Section
      title="Joueurs"
      description="Liste des joueurs"
      onClick={() => navigate('/edit/player')}>
      <Table
        data={tableData?.map((o) => ({
          id: o?.id,
          clubName: (
            <div order={o?.club?.name || 'z'} className="w-full flex flex-row items-center gap-2">
              <img className="h-[30px]" src={o?.club?.logo?.url} />
              <p>{o?.club?.name || 'Club non assigné'}</p>
            </div>
          ),
          name: o?.name,
          number: o?.number,
          clubNameShown: o?.club?.name || 'Club non assigné'
        }))}
        onClick={(_, o) => navigate(`/edit/player/${o?.id}`)}
        labels={['Id', 'Nom', 'Numéro', 'Club']}
        groupBy={'clubName'}
        paginate={100}
        className={'mt-2 sm:mt-5'}
        isLoading={isLoading}
      />
    </Section>
  );
}

export default PlayerPage;
