import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { EditForm } from '@custom';
import { InputFile, InputText } from '@components';

import queries from '@queries';

const ClubEdit = () => {
  const { id } = useParams();

  const { data: club } = useQuery(
    ['clubs', id],
    () =>
      queries
        .get(`/api/clubs`, id, { populate: ['logo', 'font', 'players'] })
        .then((res) => res.data),
    { enabled: !(typeof id === 'undefined' || id === null) }
  );

  return (
    <EditForm
      title="Club"
      description="Le formulaire demande généralement des informations de base sur le club, telles que son nom, le logo et la police d'écriture du club.">
      <input type="hidden" name="id" defaultValue={club?.id} />
      <InputText name="name" label="Nom du club" defaultValue={club?.name} />
      <InputFile name="files.logo" label="Logo du club" defaultValue={club?.logo} />
      <InputFile name="files.font" label="Police du club" defaultValue={club?.font} />
    </EditForm>
  );
};

export default ClubEdit;
