import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import baseUrl from '@root/config';
import { EditForm } from '@custom';
import { InputText, Combobox, InputFile, Button } from '@components';

import queries from '@queries';

const SaisonEdit = () => {
  const { id } = useParams();

  const { data: season } = useQuery(
    ['seasons', id],
    () =>
      queries
        .get(`/api/seasons`, id, {
          populate: [
            'matches.club1.logo',
            'matches.club2.logo',
            'club',
            'current_match.club1',
            'current_match.club2',
            'logo_event',
            'logo_sponsor',
            'logo_qr_print',
            'logo_pronostic',
            'logo_resultats',
            'logo_statistics',
            'reglement'
          ]
        })
        .then((res) => res.data),
    { enabled: !(typeof id === 'undefined' || id === null) }
  );

  const { data: matches } = useQuery(['matches'], () =>
    queries
      .getAll(`/api/matches`, { populate: ['club1.logo', 'club2.logo', 'season'] })
      .then((res) => res.data)
  );

  const { data: clubs } = useQuery(['clubs'], () =>
    queries.getAll(`/api/clubs`).then((res) => res.data)
  );

  return (
    <EditForm
      title="Saison"
      description={
        <div className="space-y-2">
          <p>
            Le formulaire demande généralement des informations de base sur le club, telles que son
            nom, son logo et la police d&apos;écriture du club.
          </p>
          {id && (
            <>
              <p>
                Vous pouvez également exporter au format CSV les statistiques de la saison en cours
                :
              </p>
              <Button
                onClick={() => window.open(`${baseUrl}/api/report?idSeason=${id}`, '_blank')}
                className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-full  dark:bg-gray-900 py-1 px-3 focus:ring-teal-400/20 bg-gray-700 hover:bg-gray-900 dark:bg-teal-400/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300">
                Export des statistiques
              </Button>
            </>
          )}
        </div>
      }>
      <input type="hidden" name="id" defaultValue={season?.id} />

      <InputText
        classNameInput="dark:text-gray-900"
        name="name"
        label="Nom de la saison *"
        defaultValue={season?.name}
        required
      />
      <InputText
        classNameInput="dark:text-gray-900"
        name="start"
        label="Date de début de saison *"
        type="date"
        defaultValue={season?.start}
        required
      />
      <InputText
        name="end"
        classNameInput="dark:text-gray-900"
        label="Date de fin de saison*"
        type="date"
        defaultValue={season?.end}
        required
      />
      <Combobox
        name="club"
        label="Club principal *"
        data={clubs}
        defaultValue={season?.club}
        displayValue={(p) => p?.name}
        displayOptions={(p) => p?.name}
        filter={(p, query) => p?.name?.toLowerCase()?.includes(query?.toLowerCase())}
        allowSpace
        required
      />
      <Combobox
        name="current_match"
        label="Match actuel"
        data={matches?.filter((m) => m?.season?.id === season?.id)}
        defaultValue={season?.current_match}
        displayValue={(p) => (p ? `${p?.club1?.name} x ${p?.club2?.name}` : '')}
        displayOptions={(p) => `${p?.club1?.name} x ${p?.club2?.name}`}
        filter={(p, query) =>
          p?.club1?.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
          p?.club2?.name?.toLowerCase()?.includes(query?.toLowerCase())
        }
        allowSpace
      />
      <InputFile
        name="files.logo_event"
        label="Logo de l'évenement"
        defaultValue={season?.logo_event}
        accept=".png,.jpeg,.jpg"
      />
      <InputFile
        name="files.logo_sponsor"
        label="Logo du sponsor"
        defaultValue={season?.logo_sponsor}
        accept=".png,.jpeg,.jpg"
      />
      <InputFile
        name="files.logo_qr_print"
        label="Logo du QR Print"
        defaultValue={season?.logo_qr_print}
        accept=".png,.jpeg,.jpg"
      />
      <InputFile
        name="files.logo_pronostic"
        label="Logo Pronostic"
        defaultValue={season?.logo_pronostic}
        accept=".png,.jpeg,.jpg"
      />
      <InputFile
        name="files.logo_resultats"
        label="Logo des Résultats"
        defaultValue={season?.logo_resultats}
        accept=".png,.jpeg,.jpg"
      />
      <InputFile
        name="files.logo_statistics"
        label="Logo Statisques"
        defaultValue={season?.logo_statistics}
        accept=".png,.jpeg,.jpg"
      />
      <InputFile
        name="files.reglement"
        label="Règlement"
        defaultValue={season?.reglement}
        accept=".pdf"
      />
    </EditForm>
  );
};

export default SaisonEdit;
