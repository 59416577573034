import { useRef, useState, useEffect } from 'react';
import { Carousel } from '@components';
import { DocumentIcon, PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid';

// eslint-disable-next-line max-lines-per-function
const MultipleInputFile = (props) => {
  const {
    label = '',
    accept = '*',
    setFile = () => {},
    limit = 3,
    defaultValue = [],
    name = '',
    ...rest
  } = props;

  const input = useRef(null);
  const modifiedFile = useRef(null);
  const [previews, setPreviews] = useState(defaultValue);
  const [index, setIndex] = useState(0);
  const inputRef = useState(null);

  const removeFromPreview = (idx) => {
    setPreviews((prev) => [...prev.slice(0, idx), ...prev.slice(idx + 1)]);
    removeFileFromFileList(idx);

    if (idx >= previews.length - 1) setIndex(idx - 1);
  };

  const removeFileFromFileList = (index) => {
    const dt = new DataTransfer();
    const { files } = input.current;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (index !== i) dt.items.add(file); // here you exclude the file. thus removing it.
    }

    input.current.files = dt.files; // Assign the updates list
  };

  const renderAccept = (str) =>
    str === '*'
      ? ''
      : str
          .split(',')
          .map((o) => o.replace('.', '').toUpperCase())
          .join(', ');

  const cancelEffect = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const updatePreview = (file) => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => setPreviews((prev) => [...prev, { img: reader.result, name: file.name }]),
      false
    );
    if (['image/svg+xml', 'image/png', 'image/jpeg', 'image/gif'].includes(file.type))
      reader.readAsDataURL(file);
    else setPreviews((prev) => [...prev, { name: file.name }]);
  };

  const uploadFile = (e, type = 'click') => {
    e.preventDefault();
    e.stopPropagation();

    let files = null;

    if (type === 'click') files = e.target.files;
    else {
      files = e.dataTransfer.files;
      inputRef.current.files = files;
    }

    for (let i = 0; i < files.length; i++) {
      Object.defineProperty(files.item(i), 'name', {
        writable: true,
        value: files
          .item(i)
          .name.normalize('NFD')
          .replace(/[\u0300-\uFFFF]/g, '')
          .replace(/ /g, '-')
      });
    }

    setFile(files);
    setPreviews([]);

    // Limit the number of files to the number indicated in the file
    for (let i = 0; i < Math.min(files.length, limit); i++) {
      updatePreview(files.item(i));
    }
  };

  const modifyFile = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Create dynamically a simple input file
    const input = document.createElement('input');
    input.style.display = 'none';
    input.type = 'file';
    document.body.append(input);

    // Create a tmp object
    input.addEventListener('change', (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        // Make an tmp array with the new element
        let tmp = [...previews];
        tmp[index] = { img: reader.result, name: file.name };

        setPreviews(tmp);
        setFile(tmp);
      };
      reader.readAsDataURL(file);

      // Remove the `<input type="file"/>` again from the DOM.
      input.remove();
    });
    input.click();
  };

  const addFile = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.target.files[0];

    updatePreview(file);
  };

  useEffect(() => {
    setPreviews(defaultValue);
  }, [defaultValue]);

  return (
    <div className="relative w-full group">
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
        {label}
      </label>
      <div className={`flex items-center justify-center w-full`}>
        <label
          htmlFor={`dropzone-multiple-file_${name}`}
          className={`${
            previews.length === 0 && 'cursor-pointer'
          } flex flex-col items-center justify-center w-full h-32 border border-gray-300 rounded-lg overflow-hidden bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-800 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-700 transition`}
          onDrag={(e) => cancelEffect(e)}
          onDragEnter={(e) => cancelEffect(e)}
          onDragLeave={(e) => cancelEffect(e)}
          onDragEnd={(e) => cancelEffect(e)}
          onDragOver={(e) => cancelEffect(e)}
          onDragStart={(e) => cancelEffect(e)}
          onDrop={(e) => uploadFile(e, 'DND')}>
          <div
            className={`flex ${
              previews.length > 0 && 'flex-col'
            } items-center gap-6 w-full h-full`}>
            {previews.length > 0 ? (
              <div
                className="absolute w-full h-[7.9rem] flex"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                <div className="w-1/2 h-31 overflow-hidden rounded-l-md border-x dark:border-gray-600">
                  <Carousel
                    data={previews?.map((o, index) =>
                      ['png', 'jpeg', 'jpg', 'gif', 'svg'].some((l) => o?.img?.includes(l)) ? (
                        <img key={index} className="h-full w-full object-contain" src={o.img} />
                      ) : (
                        <div
                          key={index}
                          className="h-full rounded-md flex items-center justify-center">
                          <DocumentIcon className="h-10 w-10 dark:text-gray-200 transition" />
                        </div>
                      )
                    )}
                    hideDot={true}
                    isControled={true}
                    index={index}
                    setIndex={setIndex}
                  />
                </div>
                <div className="relative  w-1/2">
                  <span className="absolute transition text-sm sm:text-md right-2 bottom-0 text-gray-500">{`${
                    index + 1
                  } / ${previews.length}`}</span>
                  {previews.map((obj, idx) => (
                    <div
                      key={`side_sliders_${idx}`}
                      className={`absolute transition w-full h-full flex flex-col justify-evenly items-center ${
                        idx === index ? 'opacity-100' : 'opacity-0'
                      }`}>
                      <p className="dark:text-gray-200 pl-2">{obj.name}</p>
                      <div className="flex space-x-2">
                        <button
                          ref={modifiedFile}
                          onClick={(e) => modifyFile(e)}
                          className="p-1 transition bg-gray-200 hover:bg-gray-300 hover:text-gray-600 dark:bg-gray-600 dark:hover:bg-gray-500 dark:hover:text-gray-700  text-gray-500 rounded-md">
                          <PencilSquareIcon className="w-4 h-4" />
                        </button>
                        <button
                          onClick={() => removeFromPreview(index)}
                          className="p-1 transition bg-gray-200 hover:bg-gray-300 hover:text-gray-600 dark:bg-gray-600 dark:hover:bg-gray-500 dark:hover:text-gray-700  text-gray-500 rounded-md">
                          <TrashIcon className="w-4 h-4" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="absolute left-0 flex items-center justify-center gap-6 w-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 stroke-gray-400">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
                <div>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400 text-center">
                    {renderAccept(accept)}
                  </p>
                </div>
              </div>
            )}
          </div>
          <input
            id={`dropzone-multiple-file_${name}`}
            type="file"
            className="hidden"
            ref={input}
            accept={accept}
            onChange={(e) => uploadFile(e)}
            multiple
            {...rest}
          />
        </label>
      </div>
      <div className="w-full flex justify-between items-center px-2 py-1">
        <span className="text-sm sm:text-md text-gray-500">{`* Limited to ${limit} element${
          limit > 1 && 's'
        }`}</span>
        {previews.length > 0 && previews.length < limit ? (
          <label
            htmlFor="add-input-file"
            className="cursor-pointer px-1.5 py-0.5 border dark:border-gray-600 bg-gray-200 dark:bg-gray-700 text-gray-500 rounded-md">
            <PlusIcon className="w-4 h-4" />
            <input
              id="add-input-file"
              type="file"
              className="hidden"
              ref={inputRef}
              accept={accept}
              onChange={(e) => addFile(e)}
            />
          </label>
        ) : null}
      </div>
    </div>
  );
};

export default MultipleInputFile;
