import { useEffect, useState } from 'react';
import { ChevronDownIcon, CheckIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';

// eslint-disable-next-line max-lines-per-function
function SingleCombobox(props) {
  const {
    label = '',
    name = '',
    defaultValue = null,
    data = [],
    displayValue = () => {},
    displayOptions = () => {},
    filter = () => true,
    className = '',
    classNameInput = '',
    classNameLabel = '',
    classNameOptions = '',
    valueKey = (data) => data?.id,
    icon: Icon = MagnifyingGlassIcon,
    value: valueControlled = '',
    setValue: setValueControlled = () => {},
    isControlled = false,
    required = false,
    onChange = () => {},
    allowSpace = false,
    noDataLabel = 'No data is corresponding...',
    ...rest
  } = props;

  const [query, setQuery] = useState('');
  const [selectedInternal, setSelectedInternal] = useState(defaultValue);
  const [selectedLiInternal, setSelectedLi] = useState(0);

  const filteredData = query === '' ? data : data.filter((o) => filter(o, query));

  // Switch between local or external state
  const selected = isControlled ? valueControlled : selectedInternal;
  const setSelected = isControlled ? setValueControlled : setSelectedInternal;

  // Reset query when selected
  useEffect(() => {
    setQuery(displayValue(selected) || '');
  }, [selected]);

  // Update data when the default value is updated
  useEffect(() => {
    setSelectedInternal(defaultValue);
  }, [defaultValue]);

  // Handle keydowns event (ArrownDown, ArrowUp, Enter, Space)
  const switchOption = (e) => {
    if ([40, 38, 13, allowSpace ? '' : 32].includes(e.keyCode)) e.preventDefault();
    if (e.keyCode === 40) setSelectedLi(Math.min(selectedLi + 1, filteredData.length - 1));
    else if (e.keyCode === 38) setSelectedLi(Math.max(selectedLi - 1, -1));
    else if ([8, 46].includes(e.keyCode)) setSelected('');
    else if ([13, allowSpace ? '' : 32].includes(e.keyCode))
      valueKey(filteredData?.[selectedLi]) === valueKey(selected)
        ? setSelected(null)
        : setSelected(filteredData?.[selectedLi]);
  };

  const selectedLi = Math.max(Math.min(selectedLiInternal, filteredData.length - 1), 0);

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={'block text-sm font-medium text-gray-700 dark:text-gray-200' + classNameLabel}>
        {label}
      </label>
      {!isControlled && (
        <input
          className="opacity-0 absolute inset-x-1/2 w-1/4 bg-transparent"
          name={name}
          defaultValue={valueKey(selected) ?? ''}
          required={required}
        />
      )}
      <div className="relative">
        {Icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none hover:text-white cursor-pointer">
            <Icon className="h-1/2 text-gray-400" aria-hidden="true" />
          </div>
        )}
        <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pointer-events-none focus:outline-none">
          <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          value={query}
          autoComplete="off"
          onKeyDown={(e) => switchOption(e)}
          onChange={(e) => {
            onChange(e.target.value);
            setQuery(e.target.value);
          }}
          onFocus={(event) => {
            event.target.select();
            setSelectedLi(0);
          }}
          className={`peer mt-1 block w-full shadow-sm bg-gray-100 dark:bg-gray-800 focus:ring-teal-500 focus:border-teal-500 sm:text-sm border-gray-300 dark:border-gray-700 dark:text-gray-200 rounded-md ${
            Icon && 'pl-10'
          } ${classNameInput}`}
          id={name}
          {...rest}
        />
        <ul
          id={`list_${name}`}
          tabIndex={0}
          className={`absolute top-11 left-0 z-10 max-h-60 w-full overflow-auto roundeddark:text-gray-200 -md bg-white dark:bg-gray-800 shadow-lg rounded-lg ring-1 ring-black dark:ring-gray-700 ring-opacity-5 focus:outline-none text-sm invisible peer-focus:visible hover:visible focus:visible`}>
          {filteredData.length === 0 ? (
            <li
              className={
                'group relative cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-teal-600 hover:text-white bg-teal-600 text-white  ' +
                classNameOptions
              }>
              {noDataLabel}
            </li>
          ) : (
            filteredData?.map((o, index) => (
              <li
                key={`option_${index}`}
                onClick={() =>
                  valueKey(o) === valueKey(selected) ? setSelected(null) : setSelected(o)
                }
                className={
                  'group relative cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-teal-600 hover:text-white ' +
                  (selectedLi === index
                    ? ' bg-teal-600 text-white '
                    : 'text-gray-900 dark:text-gray-200') +
                  classNameOptions
                }>
                {displayOptions(o)}
                {valueKey(o) === valueKey(selected) && (
                  <span
                    className={
                      'absolute inset-y-0 left-0 flex items-center pl-3  group-hover:text-white ' +
                      (selectedLi === index ? ' text-white ' : ' text-teal-600 ')
                    }>
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                )}
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
}

export default SingleCombobox;
