import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import queries from '@queries';
import { getQuery } from '@queries/utils';
import { Button, Loading, Table, Modal } from '@components';
import { fetchDistributionBet, fetchMostPredicted, fetchWinner } from '@queries/Statistics';
import ballon from '@assets/ballon.png';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleUndefined = (val) => (val === undefined || isNaN(val) ? '~' : val);

  const queryClient = useQueryClient();

  const { data: seasonsRaw, isLoading: seasonLoading } = useQuery(['seasons'], () =>
    queries
      .getAll(`/api/seasons`, {
        populate: [
          'current_match.club1',
          'current_match.club2',
          'current_match.club1.players',
          'current_match.club2.players',
          'current_match.club1.logo',
          'current_match.club2.logo',
          'logo_event',
          'logo_sponsor',
          'logo_qr_print',
          'logo_pronostic',
          'logo_resultats',
          'logo_statistics',
          'club.font',
          'reglement'
        ]
      })
      .then((res) => res.data)
  );

  const season = seasonsRaw?.at(-1);

  const { data: bets, isLoading: betsLoading } = useQuery(
    ['bets', 'season', season?.id],
    () =>
      queries
        .getAll(`/api/bets`, {
          populate: ['loge', 'player', 'match.club2', 'match.club1'],
          filters: { match: { id: season?.current_match?.id } }
        })
        .then((res) => res.data),
    { enabled: !seasonLoading } // Only after season is loaded
  );

  // Set Most Predicted
  const { data: mostPredicted, isLoading: mostPredictedIsLoading } = useQuery(
    [season?.current_match?.id, 'mostPredicted'],
    () => fetchMostPredicted(season?.current_match?.id)
  );

  // Set Distribution Bet
  const { data: distributionBet, isLoading: distributionBetIsLoading } = useQuery(
    [season?.current_match?.id, 'distributionBet'],
    () => fetchDistributionBet(season?.current_match?.id)
  );

  // Set Winner
  const { data: winner, isLoading: winnerIsLoading } = useQuery(
    [season?.current_match?.id, 'winner'],
    () => fetchWinner(season?.current_match?.id)
  );

  const computeRewardMutation = useMutation(
    (id) => getQuery('/api/compute-reward', `idMatch=${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['bets'] });
        queryClient.invalidateQueries([season?.current_match?.id, 'winner']);
      }
    }
  );

  const stats = [
    {
      id: 1,
      name: 'Gagnant / Loge',
      value:
        winner?.name !== null && winner?.reward !== null
          ? `${winner?.name} / ${handleUndefined(winner?.loge?.name?.split(' ')?.at(-1))}`
          : '~'
    },
    {
      id: 2,
      name: 'Victoire / Nulle / Défaite (%)',
      value: `${handleUndefined(Math.round(distributionBet?.v * 100))} / ${handleUndefined(
        Math.round(distributionBet?.n * 100)
      )} / ${handleUndefined(Math.round(distributionBet?.l * 100))}`
    },
    {
      id: 3,
      name: 'Le plus parié',
      value: `${handleUndefined(mostPredicted?.bet_club_1)} - ${handleUndefined(
        mostPredicted?.bet_club_2
      )}`
    }
  ];

  return (
    <div className="min-h-[90vh] flex items-center justify-center flex-col">
      <div className=" py-2 sm:py-3">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="text-center">
            <div className="mt-4 text-lg text-gray-300">
              {!seasonLoading ? (
                <div className="flex justify-center h-[150px]">
                  <img
                    className="object-scale-down mr-1"
                    src={season?.current_match?.club1?.logo?.url}
                  />
                  <span className="self-center text-gray-500 dark:text-gray-400 px-2">VS</span>
                  <img
                    className="object-scale-down ml-2"
                    src={season?.current_match?.club2?.logo?.url}
                  />
                </div>
              ) : (
                <Loading src={ballon} className={'h-8 animate-spin'} />
              )}
            </div>
          </div>
          <dl className="mt-8 grid grid-cols-1 gap-y-16 gap-x-8 text-center lg:grid-cols-3">
            {!seasonLoading &&
              !betsLoading &&
              !mostPredictedIsLoading &&
              !distributionBetIsLoading &&
              !winnerIsLoading &&
              stats?.map((stat) => (
                <div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
                  <dt className="text-base leading-7 text-gray-500 dark:text-gray-400">
                    {stat.name}
                  </dt>
                  <dd className="order-first text-3xl font-semibold tracking-tight text-gray-800 dark:text-white sm:text-5xl">
                    {stat.value}
                  </dd>
                </div>
              ))}
          </dl>
        </div>
      </div>

      {!seasonLoading && (
        <div className="flex justify-end mt-6">
          <Button
            className="ml-5 inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-full  dark:bg-gray-900 py-1 px-3 focus:ring-red-400/20 bg-red-700 hover:bg-red-900 dark:bg-red-400/10 dark:text-red-400 dark:ring-1 dark:ring-inset dark:ring-red-400/20 dark:hover:bg-red-400/10 dark:hover:text-red-300 dark:hover:ring-red-300"
            onClick={() => setIsOpen(true)}
            isLoading={computeRewardMutation?.isLoading}>
            Calculer les résultats
          </Button>

          <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="p-6 space-y-5">
              <div className="flex justify-center h-[50px] mb-2">
                <img className="object-scale-down" src={season?.current_match?.club1?.logo?.url} />
                <span className="self-center text-gray-500 dark:text-gray-400 px-2 mx-2">VS</span>
                <img className="object-scale-down" src={season?.current_match?.club2?.logo?.url} />
              </div>
              <div className="flex justify-center h-[50px] mb-2">
                <span className="self-center text-gray-900 dark:text-gray-200 px-2 mx-2">
                  Êtes-vous sur de vouloir calculer les résultats maintenant ?
                </span>
              </div>

              <div className="flex justify-evenly">
                <Button
                  className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-full  dark:bg-gray-900 py-1 px-3 focus:ring-red-400/20 bg-red-700 hover:bg-red-900 dark:bg-red-400/10 dark:text-red-400 dark:ring-1 dark:ring-inset dark:ring-red-400/20 dark:hover:bg-red-400/10 dark:hover:text-red-300 dark:hover:ring-red-300"
                  type="button"
                  onClick={() => setIsOpen(false)}>
                  Annuler
                </Button>
                <Button
                  className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-full  dark:bg-gray-900 py-1 px-3 focus:ring-teal-400/20 bg-gray-700 hover:bg-gray-900 dark:bg-teal-400/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300"
                  type="button"
                  isLoading={computeRewardMutation?.isLoading}
                  onClick={() => {
                    computeRewardMutation.mutate(season?.current_match?.id);
                    setIsOpen(false);
                  }}>
                  Valider
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      )}
      <div className="w-full">
        <Table
          data={bets?.map((o) => ({
            match: `${o?.match?.club1?.name} x ${o?.match?.club2?.name}`,
            loge: o?.loge?.name,
            name: o?.name,
            player: o?.player?.name,
            bet1: o?.bet_club_1,
            bet2: o?.bet_club_2,
            reward: o?.reward
          }))}
          labels={[
            'Match',
            'Loge',
            'Nom du parieur',
            'Joueur',
            'Pari équipe 1',
            'Pari équipe 2',
            'Score'
          ]}
          hideEdit={true}
          isLoading={betsLoading}
          paginate={10}
        />
      </div>
    </div>
  );
};

export default Home;
