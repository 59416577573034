import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import _ from 'lodash';

const HotKeys = (props) => {
  const { data = [] } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleUserKeyPress = (event) => {
    const { key, altKey, shiftKey, ctrlKey, metaKey } = event;

    if (key === '?') {
      setIsOpen(!isOpen);
    } else {
      for (let shortcut of data) {
        const keys = shortcut?.key?.split('+');

        const isAlt = keys?.some((k) => k?.toLowerCase() === 'alt');
        const isShift = keys?.some((k) => k?.toLowerCase() === 'shift');
        const isCtrl = keys?.some((k) => k?.toLowerCase() === 'ctrl');
        const isMeta = keys?.some((k) => k?.toLowerCase() === '⌘');

        if (
          altKey === isAlt &&
          shiftKey === isShift &&
          ctrlKey === isCtrl &&
          metaKey === isMeta &&
          key.toLowerCase() === keys?.at(-1).toLowerCase()
        ) {
          event.preventDefault();
          event.stopPropagation();

          shortcut?.fn?.call();
        }
      }
    }
  };

  const dataGroupBy = _.groupBy(data, 'title');

  // Get the key on keydown
  useEffect(() => {
    document.addEventListener('keydown', handleUserKeyPress);

    return () => document.removeEventListener('keydown', handleUserKeyPress);
  });

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="p-3 dark:text-white transition-all?,?">
        <h4 className="mb-3">Keyboard shortcuts</h4>

        {Object.keys(dataGroupBy).map((title, idx) => (
          <div key={`title_hotkey_${idx}`}>
            <div className="grid relative grid-cols-3 my-2 text-sm">
              <div className="col-start-2 col-span-2 font-bold">{title}</div>
            </div>

            {dataGroupBy?.[title]?.map((shortcut, id) => (
              <div key={`shortcut_${id}`} className="grid relative grid-cols-3 text-sm">
                <div className=" mr-3 mb-1 flex justify-end">
                  {shortcut?.key?.split('+').map((o, index) => (
                    <div key={`shortcut_item_${index}`} className="flex">
                      {index > 0 && <p className="px-1">+</p>}
                      <span className="px-1 border border-b-[3px] border-r-2 rounded-sm transition bg-gray-100 dark:bg-gray-600 dark:border-gray- text-gray-800 dark:text-gray-200">
                        {o}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="col-span-2 dark:text-gray-100">{shortcut?.description}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default HotKeys;
