import { Section } from '@custom';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import queries from '@queries';
import { Table } from '@components';
import moment from 'moment';

function LogePage() {
  const navigate = useNavigate();

  const { data: tableData, isLoading: isLoading } = useQuery(['loges'], () =>
    queries.getAll(`/api/loges`, { populate: ['logo'] }).then((res) => res.data)
  );

  return (
    <Section title="Loges" description="Liste des loges" onClick={() => navigate('/edit/loge')}>
      <Table
        data={tableData?.map((o) => ({
          id: o?.id,
          logo: (
            <div className="flex h-[30px]">
              <img className="object-scale-down" src={o?.logo?.url} />
            </div>
          ),
          name: o?.name,
          doc: moment(o?.updatedAt).format('DD/MM/YYYY')
        }))}
        onClick={(_, o) => navigate(`/edit/loge/${o?.id}`)}
        labels={['Id', 'Logo', 'Nom', 'Date de création']}
        className={'mt-2 sm:mt-5'}
        isLoading={isLoading}
      />
    </Section>
  );
}

export default LogePage;
