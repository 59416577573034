export default {
  DEV: 'http://localhost:1337',
  STAGING: 'https://pronostic-staging-back.herokuapp.com',
  PRODUCTION: 'https://pronostic-prod-back.herokuapp.com'
  // eslint-disable-next-line no-undef
}[process.env.REACT_APP_ENV || 'DEV'];

export const hotKeys = (scope) => {
  const { navigate, links, titles, capitalize } = scope;

  const ctrlOrCmd = window?.navigator?.userAgent?.includes('Mac') ? '⌘' : 'Ctrl';

  const hotkeysNav = Object.keys(links)?.map((e) => ({
    title: 'Raccourci de navigation',
    description: `Aller à la page ${capitalize(titles?.[e])}`,
    fn: () => navigate(`/${e}`),
    key: `${ctrlOrCmd}+${titles?.[e][0].toLowerCase()}`
  }));

  const hotkeysAddNav = Object.keys(links)
    ?.map((e) =>
      e === 'home'
        ? null
        : {
            title: "Raccourci de formulaire d'ajout",
            description: `Aller au formulaire ${capitalize(titles?.[e])}`,
            fn: () => navigate(`/edit/${e}`),
            key: `${ctrlOrCmd}+shift+${titles?.[e][0].toLowerCase()}`
          }
    )
    .filter((element) => element !== null);

  const arrayToRender = hotkeysNav.concat(hotkeysAddNav);

  return arrayToRender;
};
