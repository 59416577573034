import { Section } from '@custom';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import queries from '@queries';
import moment from 'moment';
import { Table } from '@components';

function SeasonPage() {
  const navigate = useNavigate();

  const { data: tableData, isLoading: isLoading } = useQuery(['seasons'], () =>
    queries
      .getAll(`/api/seasons`, {
        populate: [
          'current_match.club1',
          'current_match.club2',
          'current_match.club1.players',
          'current_match.club2.players',
          'current_match.club1.logo',
          'current_match.club2.logo',
          'logo_event',
          'logo_sponsor',
          'logo_qr_print',
          'logo_pronostic',
          'logo_resultats',
          'logo_statistics',
          'club.font',
          'reglement'
        ]
      })
      .then((res) => res.data)
  );

  return (
    <Section
      title="Saisons"
      description="Liste des saisons"
      onClick={() => navigate('/edit/season')}>
      <Table
        data={tableData?.map((o) => ({
          id: o?.id,
          current_match: (
            <div className="flex h-[30px]">
              <img className="object-scale-down mr-1" src={o?.current_match?.club1?.logo?.url} />
              <span className="self-center">x</span>
              <img className="object-scale-down ml-1" src={o?.current_match?.club2?.logo?.url} />
            </div>
          ),
          name: o?.name,
          start: moment(o?.start).format('DD-MM-YYYY'),
          end: moment(o?.end).format('DD-MM-YYYY')
        }))}
        onClick={(_, o) => navigate(`/edit/season/${o?.id}`)}
        labels={['Id', 'Match en cours', 'Nom', 'Début', 'Fin']}
        className={'mt-2 sm:mt-5'}
        isLoading={isLoading}
      />
    </Section>
  );
}

export default SeasonPage;
