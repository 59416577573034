import axios from 'axios';
import { useState } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import logo from '@assets/logo.svg';
import ballon from '@assets/ballon.png';

import { Form } from '@layouts';
import queries from '@queries';
import baseUrl from '@root/config';
import { configToken } from '@queries/utils';

import { Button, InputText, Alert, Loading } from '@components';

function AdminAuth({ children }) {
  const queryClient = useQueryClient();
  const me = useQuery(['me'], () => queries.getAll('/api/users/me'), { retry: false });
  const loginMutation = useMutation((data) =>
    axios.post(`${baseUrl}/api/auth/local`, data?.[0], configToken)
  );

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return me?.isLoading ? (
    <Loading src={ballon} className={'animate-spin h-8'} />
  ) : me?.isSuccess ? (
    <div className="bg-gray-100 dark:bg-gray-900 ">{children}</div>
  ) : (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-50/50 dark:bg-gray-900">
      <div className="overflow-hidden shadow rounded-lg divide-y bg-white border dark:bg-gray-800/50 dark:border-gray-700 divide-gray-200  dark:divide-gray-800">
        <div className="px-4 py-5 sm:px-6 flex flex-col items-center justify-center min-w-[20rem]">
          <img className="invert max-w-[10rem] dark:invert-0" src={logo} />
          <p className="mt-4 text-base font-montserrat dark:text-white">
            Let&apos;s Smile - Concours de Pronostic
          </p>
        </div>
        <Form
          mutation={loginMutation}
          callback={(r) => {
            sessionStorage.setItem('token', r?.data?.jwt);
            queryClient.invalidateQueries('me');
            window.location.reload();
          }}
          setIsError={setIsError}
          setIsLoading={setIsLoading}>
          <div className="px-4 py-6 sm:p-6 flex flex-col gap-4">
            {isError && (
              <Alert color="red" className="mb-4">
                Le nom d&apos;utilisateur et le mot <br />
                de passe ne semble pas correspondre.
              </Alert>
            )}
            <InputText name="identifier" label="Utilisateur" />
            <InputText type="password" name="password" label="Mot de passe" />
            <div className="flex flex-col sm:flex-row-reverse ">
              <Button
                type="submit"
                isWrong={isError}
                isLoading={isLoading}
                onClick={() => {}}
                className={
                  'inline-flex mt-3 gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-full bg-zinc-900 py-1 px-3 text-white hover:bg-zinc-700 dark:bg-emerald-400/10 dark:text-emerald-400 dark:ring-1 dark:ring-inset dark:ring-emerald-400/20 dark:hover:bg-emerald-400/10 dark:hover:text-emerald-300 dark:hover:ring-emerald-300'
                }>
                Se connecter
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AdminAuth;
