import { Section } from '@custom';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import queries from '@queries';
import { Table } from '@components';

function ClubPage() {
  const navigate = useNavigate();

  const { data: tableData, isLoading: isLoading } = useQuery(['clubs'], () =>
    queries.getAll(`/api/clubs`, { populate: ['logo', 'font', 'players'] }).then((res) => res.data)
  );

  return (
    <Section title="Clubs" description="Liste des clubs" onClick={() => navigate('/edit/club')}>
      <Table
        data={tableData?.map((o) => ({
          id: o?.id,
          logo: (
            <div className="flex h-[30px]">
              <img className="object-scale-down" src={o?.logo?.url} />
            </div>
          ),
          name: o?.name
        }))}
        onClick={(_, o) => navigate(`/edit/club/${o?.id}`)}
        labels={['Id', 'Logo du club', 'Nom du club']}
        className={'mt-2 sm:mt-5'}
        isLoading={isLoading}
      />
    </Section>
  );
}

export default ClubPage;
