import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { EditForm } from '@custom';
import { Combobox, Toggle, InputText } from '@components';

import queries from '@queries';
import moment from 'moment';
import { useState } from 'react';
import _ from 'lodash';

const ComboboxClub = (props) => {
  const { clubs, clubLabel, clubField, club, setClub = () => {} } = props;

  return (
    <>
      <input type="hidden" name={clubField} value={club?.id} />
      <Combobox
        name={clubField}
        label={clubLabel}
        data={clubs}
        displayValue={(p) => p?.name}
        displayOptions={(p) => p?.name}
        filter={(p, query) => p?.name?.toLowerCase()?.includes(query?.toLowerCase())}
        allowSpace={true}
        isControlled={true}
        value={club}
        setValue={setClub}
      />
    </>
  );
};

const ComboboxsClub = (props) => {
  const { clubs, match, players } = props;

  const [club1, setClub1] = useState(match?.club1);
  const [club2, setClub2] = useState(match?.club2);

  const playersFromClub = players?.filter(
    (player) => player?.club?.id == club1?.id || player?.club?.id == club2?.id
  );

  return (
    <>
      <ComboboxClub
        clubs={clubs?.filter((e) => e.id !== club2?.id)}
        clubLabel="Club 1"
        clubField="club1"
        club={club1}
        setClub={setClub1}
      />
      <ComboboxClub
        clubs={clubs?.filter((e) => e.id !== club1?.id)}
        clubLabel="Club 2"
        clubField="club2"
        club={club2}
        setClub={setClub2}
      />
      <Toggle label="A commencé" name="hasStarted" defaultValue={match?.hasStarted} />
      <Combobox
        name="multiple.scorer_players"
        label="Joueurs ayant marqués"
        data={playersFromClub}
        defaultValue={match?.scorer_players}
        displayValue={(p) => p?.name}
        displayOptions={(p) => p?.name}
        filter={(p, query) => p?.name?.toLowerCase()?.includes(query?.toLowerCase())}
        allowSpace
        multiple
        noDataLabel="Pas de joueur correspondant..."
      />
      {(_.isEmpty(club1) || _.isEmpty(club2)) && (
        <div className="text-sm text-gray-500">Selectionner le Club 1 et 2 *</div>
      )}
    </>
  );
};

const MatchEdit = () => {
  const { id } = useParams();

  const { data: match, isLoading: isLoadingMatch } = useQuery(
    ['matches', id],
    () =>
      queries
        .get(`/api/matches`, id, { populate: ['season', 'club1', 'club2', 'scorer_players'] })
        .then((res) => res.data),
    { enabled: !(typeof id === 'undefined' || id === null) }
  );

  const { data: clubs, isLoading: isLoadingClubs } = useQuery(['clubs'], () =>
    queries.getAll(`/api/clubs`, { populate: ['logo', 'font', 'players'] }).then((res) => res.data)
  );

  const { data: seasons, isLoading: isLoadingSeasons } = useQuery(['seasons'], () =>
    queries
      .getAll(`/api/seasons`, {
        populate: [
          'current_match.club1',
          'current_match.club2',
          'current_match.club1.players',
          'current_match.club2.players',
          'current_match.club1.logo',
          'current_match.club2.logo',
          'logo_event',
          'logo_sponsor',
          'logo_qr_print',
          'logo_pronostic',
          'logo_resultats',
          'logo_statistics',
          'club.font',
          'reglement'
        ]
      })
      .then((res) => res.data)
  );

  const { data: players, isLoading: isLoadingPlayers } = useQuery(['players'], () =>
    queries.getAll(`/api/players`, { populate: ['club.logo'] }).then((res) => res.data)
  );

  return (
    <EditForm
      title="Match"
      description={`Le formulaire demande généralement des informations de base sur le math, telles que la date et l'heure, la saison concernée, les clubs correspondant. Il peut également demander des détails sur le match, notamment savoir si il a commencé et les joueurs ayant marquée par club.`}>
      {[!isLoadingClubs, !isLoadingPlayers, !isLoadingSeasons, id ? !isLoadingMatch : true].every(
        Boolean
      ) && (
        <>
          <input type="hidden" name="id" defaultValue={match?.id} />
          <InputText
            name="start_date"
            type="datetime-local"
            label="Date de début"
            defaultValue={moment(match?.start_date)
              .utcOffset('+0000', true)
              .format('YYYY-MM-DDTHH:mm')}
          />
          <Combobox
            name="season"
            label="Saison"
            data={seasons}
            defaultValue={match?.season}
            displayValue={(p) =>
              p
                ? `${p?.name} : ${moment(p?.start, 'YYYY-MM-DD').format('DD-MM-YYYY')} / ${moment(
                    p?.end,
                    'YYYY-MM-DD'
                  ).format('DD-MM-YYYY')}`
                : ''
            }
            displayOptions={(p) =>
              `${p?.name} : ${moment(p?.start, 'YYYY-MM-DD').format('DD-MM-YYYY')} / ${moment(
                p?.end,
                'YYYY-MM-DD'
              ).format('DD-MM-YYYY')}`
            }
            filter={(p, query) =>
              `${p?.name} : ${moment(p?.start, 'YYYY-MM-DD').format('DD-MM-YYYY')} / ${moment(
                p?.end,
                'YYYY-MM-DD'
              ).format('DD-MM-YYYY')}`
                ?.toLowerCase()
                ?.includes(query?.toLowerCase())
            }
            allowSpace={true}
          />

          <ComboboxsClub clubs={clubs} match={match} players={players} />

          <InputText name="score_club_1" label="Score club 1" defaultValue={match?.score_club_1} />
          <InputText name="score_club_2" label="Score club 2" defaultValue={match?.score_club_2} />
        </>
      )}
    </EditForm>
  );
};

export default MatchEdit;
