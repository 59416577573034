import React from 'react';

const BtnCarousel = ({ arrowPos = 'center', prevSlide, nextSlide }) => {
  const position = {
    left: ['top-[30%]  left-2  rotate-90', 'top-[60%]  left-2  rotate-90'],
    right: ['top-[30%]  right-2  rotate-90', 'top-[60%]  right-2  rotate-90'],
    center: ['top-1/2 -translate-y-1/2  left-2', 'top-1/2 -translate-y-1/2  right-2']
  };

  const classPositon = position[arrowPos];

  return (
    <>
      <button
        onClick={(e) => prevSlide(e)}
        className={`cursor-pointer absolute ${classPositon[0]}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          className="w-6 h-6 transition stroke-gray-300 hover:stroke-white">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button>
      <button
        onClick={(e) => nextSlide(e)}
        className={`cursor-pointer absolute ${classPositon[1]}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          className="w-6 h-6 transition stroke-gray-300 hover:stroke-white">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button>
    </>
  );
};

export default BtnCarousel;
