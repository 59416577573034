import SingleInputFile from './SingleInputFile';
import MultipleInputFile from './MultipleInputFile';

const InputFile = (props) => {
  const { multiple = undefined, ...rest } = props;

  if (multiple) return <MultipleInputFile {...rest} />;

  return <SingleInputFile {...rest} />;
};

export default InputFile;
