import { Section } from '@custom';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import queries from '@queries';
import moment from 'moment';
import { Table } from '@components';

function MatchPage() {
  const navigate = useNavigate();

  const { data: tableData, isLoading: isLoading } = useQuery(['matches'], () =>
    queries
      .getAll(`/api/matches`, { populate: ['club1.logo', 'club2.logo', 'season'] })
      .then((res) => res.data)
  );

  const sortedData = tableData?.sort((a, b) => moment(a.start_date).diff(b.start_date)).reverse();

  return (
    <Section title="Matchs" description="Liste des matches" onClick={() => navigate('/edit/match')}>
      <Table
        data={sortedData?.map((o) => ({
          id: o?.id,
          season: o?.season?.name,
          logo: (
            <div className="flex h-[30px] gap-2">
              <div className="max-w-[30px] flex flex-col justify-center items-center">
                <img className="object-scale-down mr-1" src={o?.club1?.logo?.url} />
                <p>{o?.score_club_1}</p>
              </div>
              <span className="self-center">x</span>
              <div className="max-w-[30px] flex flex-col justify-center items-center">
                <img className="object-scale-down mr-1" src={o?.club2?.logo?.url} />
                <p>{o?.score_club_2}</p>
              </div>
            </div>
          ),
          club_1: o?.club1?.name,
          club2: o?.club2?.name,
          start_date: moment(o?.start_date).format('DD-MM-YYYY'),
          start_hour: moment(o?.start_date).format('HH:mm')
        }))}
        onClick={(_, o) => navigate(`/edit/match/${o?.id}`)}
        labels={[
          'Id',
          'Saison',
          'Logo du match',
          'Club 1',
          'Club 2',
          'Date du match',
          'Heure du match'
        ]}
        className={'mt-2 sm:mt-5'}
        isLoading={isLoading}
      />
    </Section>
  );
}

export default MatchPage;
