import { Section } from '@custom';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import queries from '@queries';
import { Table } from '@components';
import moment from 'moment';

function BetPage() {
  const navigate = useNavigate();

  const { data: tableData, isLoading: isLoading } = useQuery(['bets'], () =>
    queries
      .getAll(`/api/bets`, { populate: ['loge', 'player', 'match.club2', 'match.club1'] })
      .then((res) => res.data)
  );

  const sortedData = tableData?.sort((a, b) => moment(a.createdAt).diff(b.createdAt)).reverse();

  return (
    <Section title="Paris" description="Liste des paris" onClick={() => navigate('/edit/bet')}>
      <Table
        data={sortedData?.map((o) => ({
          id: o?.id,
          match: `${o?.match?.club1?.name} x ${o?.match?.club2?.name}`,
          loge: o?.loge?.name,
          name: o?.name,
          player: o?.player?.name,
          bet1: o?.bet_club_1,
          bet2: o?.bet_club_2,
          reward: o?.reward,
          doc: moment(o?.createdAt).format('DD/MM/YYYY'),
          docHeu: moment(o?.createdAt).format('HH:mm')
        }))}
        onClick={(_, o) => navigate(`/edit/bet/${o?.id}`)}
        labels={[
          'Id',
          'Match',
          'Loge',
          'Nom du parieur',
          'Joueur',
          'Pari équipe 1',
          'Pari équipe 2',
          'Score',
          'Date de création',
          'Heure de création'
        ]}
        groupby={'match'}
        paginate={100}
        className={'mt-2 sm:mt-5'}
        isLoading={isLoading}
      />
    </Section>
  );
}

export default BetPage;
