import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { EditForm } from '@custom';
import { Combobox, InputText } from '@components';

import queries from '@queries';

const BetEdit = () => {
  const { id } = useParams();

  const { data: bet } = useQuery(
    ['bets', id],
    () =>
      queries
        .get(`/api/bets`, id, { populate: ['loge', 'player', 'match.club2', 'match.club1'] })
        .then((res) => res.data),
    { enabled: !(typeof id === 'undefined' || id === null) }
  );

  const { data: matches } = useQuery(['matches'], () =>
    queries
      .getAll(`/api/matches`, { populate: ['club1.logo', 'club2.logo'] })
      .then((res) => res.data)
  );

  const { data: loges } = useQuery(['loges'], () =>
    queries.getAll(`/api/loges`).then((res) => res.data)
  );

  const { data: players } = useQuery(['players'], () =>
    queries.getAll(`/api/players`).then((res) => res.data)
  );

  return (
    <EditForm
      title="Pari"
      description={`Le formulaire demande généralement des informations de base sur le pari, telles que le nom du parieur, la loge qui pari, le match correspondant au pari ainsi que le joueur. Il peut également demander des détails sur l'événement ou le match sur lequel le pari est placé, notamment les paris par équipes et le score.`}>
      <input type="hidden" name="id" defaultValue={bet?.id} />
      <input type="hidden" name="onAdmin" defaultValue={true} />
      <InputText name="name" label="Nom du parieur" className="mb-2" defaultValue={bet?.name} />
      <Combobox
        name="loge"
        label="Loge du parieur"
        data={loges}
        defaultValue={bet?.loge}
        displayValue={(p) => p?.name}
        displayOptions={(p) => p?.name}
        filter={(p, query) => p?.name?.toLowerCase()?.includes(query?.toLowerCase())}
        allowSpace={true}
      />
      <Combobox
        name="match"
        label="Match correspondant"
        data={matches}
        defaultValue={bet?.match}
        displayValue={(p) => (p ? `${p?.club1?.name} x ${p?.club2?.name}` : '')}
        displayOptions={(p) => `${p?.club1?.name} x ${p?.club2?.name}`}
        filter={(p, query) =>
          `${p?.club1?.name} x ${p?.club2?.name}`?.toLowerCase()?.includes(query?.toLowerCase())
        }
        allowSpace={true}
      />
      <Combobox
        name="player"
        label="Joueur parié"
        data={players}
        defaultValue={bet?.player}
        displayValue={(p) => p?.name}
        displayOptions={(p) => p?.name}
        filter={(p, query) => p?.name?.toLowerCase()?.includes(query?.toLowerCase())}
        allowSpace={true}
      />
      <InputText
        type="number"
        name="bet_club_1"
        label="Pari sur l'équipe 1"
        defaultValue={bet?.bet_club_1}
      />
      <InputText
        type="number"
        name="bet_club_2"
        label="Pari sur l'équipe 2"
        defaultValue={bet?.bet_club_2}
      />
      <InputText type="number" name="reward" label="Score" defaultValue={bet?.reward} />
    </EditForm>
  );
};

export default BetEdit;
