import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { EditForm } from '@custom';
import { InputFile, InputText } from '@components';

import queries from '@queries';

const LogeEdit = () => {
  const { id } = useParams();

  const { data } = useQuery(
    ['loges', id],
    () => queries.get(`/api/loges`, id, { populate: ['logo'] }).then((res) => res.data),
    { enabled: !(typeof id === 'undefined' || id === null) }
  );

  return (
    <EditForm
      title="Loge"
      description="Le formulaire demande généralement des informations de base sur la loge, telles que son nom et le logo de la loge.">
      <input type="hidden" name="id" defaultValue={data?.id} />
      <InputText name="name" label="Nom de la loge" className="mb-2" defaultValue={data?.name} />
      <InputFile name="files.logo" label="Logo de la loge" defaultValue={data?.logo} />
    </EditForm>
  );
};

export default LogeEdit;
