import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { Form } from '@layouts';
import { Button, Alert } from '@components';
import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline';
import queries from '@queries';
import { motion } from 'framer-motion';

const isConsonne = (str) => str?.at(-1) === 'h';

const EditForm = (props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { pageName, id } = useParams();

  const mutationCreate = useMutation((data) =>
    queries?.post(`/api/${pageName}${isConsonne(pageName) ? 'e' : ''}s`, data)
  );
  const mutationUpdate = useMutation((data) =>
    queries?.put(`/api/${pageName}${isConsonne(pageName) ? 'e' : ''}s`, data)
  );
  const mutationDelete = useMutation((data) =>
    queries?.delete(`/api/${pageName}${isConsonne(pageName) ? 'e' : ''}s`, data)
  );

  const [mutation, setMutation] = useState(mutationCreate);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ x: 100 }}
      transition={{ duration: 0.5, delay: 0.07 }}>
      <div className="md:grid md:grid-cols-3 md:gap-6 pt-6">
        <div className="md:col-span-1">
          <ArrowLeftCircleIcon
            className="w-10 h-10 text-gray-500 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <div className="ml-12">
            <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
              {props?.title}
            </h3>
            <p className="mt-1 text-sm text-gray-600">{props?.description}</p>
          </div>
        </div>

        <div className="mt-5 md:col-span-2 md:mt-0">
          <Form
            mutation={mutation}
            setIsError={setIsError}
            setIsLoading={setIsLoading}
            callback={() => {
              queryClient.invalidateQueries(pageName);
              navigate(`/${pageName}`);
            }}>
            <div className="shadow rounded-md bg-white  dark:bg-gray-800/50 dark:shadow-gray-700 border dark:border-gray-700">
              <div className="space-y-6 px-4 py-5 sm:p-4">
                <div className="grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2 space-y-4">
                    {isError && (
                      <Alert color="red" className="mb-4 md:col-span-2">
                        Une erreur s&apos;est produite lors de la modification des informations{' '}
                        <br />
                        Veuillez réessayer.
                      </Alert>
                    )}
                    {props.children}
                  </div>
                  <div className={`flex px-4 pt-5 justify-${id ? 'between' : 'end'} sm:px-6`}>
                    {id && (
                      <Button
                        color="red"
                        className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-full text-white dark:bg-red-700 py-1 px-3 focus:ring-red-400/20  hover:bg-red-700 dark:bg-red-700/10 dark:text-red-400 dark:ring-1 dark:ring-inset dark:ring-red-400/20 dark:hover:bg-red-400/10 dark:hover:text-red-300 dark:hover:ring-red-300"
                        type="submit"
                        isWrong={isError}
                        isLoading={isLoading}
                        onClick={() => setMutation(mutationDelete)}>
                        Supprimer
                      </Button>
                    )}
                    <Button
                      className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-full  dark:bg-zinc-900 py-1 px-3 focus:ring-teal-400/20 bg-gray-700 hover:bg-zinc-900 dark:bg-teal-400/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300"
                      type="submit"
                      isWrong={isError}
                      isLoading={isLoading}
                      onClick={() => setMutation(id ? mutationUpdate : mutationCreate)}>
                      {id ? 'Modifier' : 'Enregistrer'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </motion.div>
  );
};

export default EditForm;
