function Badge(props) {
  const { onClick = () => {}, children = null, className = '', ...rest } = props;

  return (
    <span
      className={`inline-flex rounded items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-white dark:bg-gray-700 dark:text-gray-100 text-black-70 relative ${className}`}
      {...rest}>
      {children}
      <button
        type="button"
        className={`absolute top-0 left-0 h-full w-full bg-transparent text-transparent flex justify-center items-center hover:bg-gray-300 rounded group opacity-90`}
        onClick={onClick}>
        <span className="sr-only">Remove large option</span>
        <svg
          className="h-2 w-2 invisible group-hover:visible"
          stroke="#FFFFFF"
          fill="none"
          viewBox="0 0 8 8">
          <path strokeLinecap="round" strokeWidth="2" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
  );
}
export default Badge;
