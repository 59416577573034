import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { EditForm } from '@custom';
import { Combobox, InputText } from '@components';

import queries from '@queries';

const PlayerEdit = () => {
  const { id } = useParams();

  const { data: player } = useQuery(
    ['players', id],
    () => queries.get(`/api/players`, id, { populate: ['club'] }).then((res) => res.data),
    { enabled: !(typeof id === 'undefined' || id === null) }
  );

  const { data: clubs } = useQuery(['clubs'], () =>
    queries.getAll(`/api/clubs`).then((res) => res.data)
  );

  return (
    <EditForm
      title="Joueur"
      description="Le formulaire demande généralement des informations de base sur le joueur, telles que son nom, son numéro et son affiliation actuelle à un club.">
      <input type="hidden" name="id" defaultValue={player?.id} />
      <InputText name="name" label="Nom du joueur" className="mb-2" defaultValue={player?.name} />
      <InputText
        name="number"
        label="Numéro du joueur"
        defaultValue={player?.number}
        className="mb-2"
      />
      <Combobox
        name="club"
        label="Club du joueur"
        data={clubs}
        defaultValue={player?.club}
        displayValue={(p) => p?.name}
        displayOptions={(p) => p?.name}
        filter={(p, query) => p?.name?.toLowerCase()?.includes(query?.toLowerCase())}
        allowSpace={true}
      />
    </EditForm>
  );
};

export default PlayerEdit;
